<template>
    <div>
        <div id="checkout"></div>
    </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, BOverlay } from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent, BCard, BCardText, BLink, BRow, BCol, BOverlay,
    },
    data(){
        return {
            stripe: null
        }
    },
    mounted(){
        this.stripe = Stripe('pk_live_51OyUQ6P9AMXwtDDm8Fa9MYPqK2l7GuCz4UNV9u5LSohR21JbWRSmMHFbxPYDHLGgkdJ0rV0YNWHHY01tpdIqFvHU00M7Dkwr5D')
        console.log(this.$route.query.plan);
        console.log(this.$route.query.period);
        if (this.$route.query.plan && this.$route.query.period){
            this.initialize(this.$route.query.plan)
        } else {
            this.$router.push('/subscription')
        }
    },
    methods: {
        async initialize(plan){
            const fetchClientSecret = async () => {
                const response = await axios.post('/create_checkout_session/',  {plan: plan})
                const { clientSecret } = await response.data;
                return clientSecret;
            };

            try {
                const checkout = await this.stripe.initEmbeddedCheckout({
                    fetchClientSecret
                });

                // Mount Checkout
                checkout.mount('#checkout');

            } catch (e){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Warning !',
                        icon: 'AlertCircleIcon',
                        text: 'Something Went Wrong. Please try again',
                        variant: 'danger',
                    },
                })
                console.log(e);
                this.$router
                    .push('/subscription')
                    .then(() => { this.$router.go() });
            }
            // await axios.post('/create_checkout_session/',  {plan: 'standard'})
            // .then(response => {
            //     console.log(response);
            //     const checkout = this.stripe.initEmbeddedCheckout({clientSecret: response.data.sessionId})
            //     checkout.mount('#checkout');
            // })
            // .catch(error => {
            //     console.log(error);
            // })
        }
    }
}
</script>